<template>
  <div class="nav">
    <div class="card-access">
      <div v-if="this.personalData[0]" class="carousel">
        <div style="padding: 0 21.5px">
          <h3 style="margin-top: 10px">
            <font-awesome-icon
              style="margin-right: 3.5px"
              :icon="['fas', 'mouse-pointer']"
            />
            Acesso Rápido
          </h3>
          <vs-divider />
        </div>
        <div class="scroll-container" ref="cardAccess">
          <div class="button-wrapper">
            <vs-button
              v-for="(button, index) in buttons"
              :key="index"
              class="button-contrast"
              @click="openLink(button.link)"
            >
              <img
                v-if="button.text == 'Moodle'"
                style="height: 48.5px; margin-bottom: -6.5px"
                src="../assets/moodle.png"
                alt="Moodle"
              />
              <img
                v-else-if="button.text == 'Minhas Faturas'"
                style="height: 45px; margin-top: -1.5px"
                src="../assets/fatura.png"
                alt="Minhas Faturas"
              />
              <img
                v-else-if="button.text == 'DesenrolaQI'"
                style="height: 45px; margin-top: -1.5px"
                src="../assets/desenrola.png"
                alt="DesenrolaQI"
              />
              <img
                v-else-if="button.text == 'Central de Vagas'"
                style="height: 45px; margin-top: -1.5px"
                src="../assets/vagas.png"
                alt="Central de Vagas"
              />
              <img
                v-else-if="button.text == 'Biblioteca Virtual'"
                style="height: 40px; margin-bottom: 5px"
                src="../assets/biblioteca.png"
                alt="Biblioteca Virtual"
              />
              <img
                v-else-if="button.text == 'Acontece aQI'"
                style="height: 25px; margin-top: 10px; margin-bottom: 7.5px"
                src="../assets/logo-bordo.png"
                alt="Acontece aQI"
              />
              <img
                v-else-if="button.text == 'Blog QI'"
                style="height: 37.5px; margin-bottom: 7.5px"
                src="../assets/blog.png"
                alt="Blog QI"
              />
              <img
                v-else-if="button.text == 'Secretaria Graduação/Pós'"
                style="height: 37.5px; margin-top: 8.5px; margin-bottom: 3.5px"
                src="../assets/sec-online.png"
                alt="Secretaria Graduação/Pós"
              />
              <font-awesome-icon
                v-else
                class="icon-contrast"
                size="2x"
                :icon="button.icon"
              />
              <p class="text-button">{{ button.text }}</p>
            </vs-button>
          </div>
        </div>
      </div>
      <div v-else class="spinner-container">
        <div class="spinner"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import { getLoginDataFromStorage } from "../helpers/storage";
import Person from "../services/person";

export default {
  name: "AcessoRapido",
  data() {
    return {
      buttons: [],
      linkAcordo: [],
      tokenExterno: null,
      tokenPearson: null,
      personalData: [],
      cpf: null,
    };
  },

  mounted() {
    const { ...user } = getLoginDataFromStorage();
    this.tokenExterno = user.externaltoken;
    this.tokenPearson = user.pearsontoken;

    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];

        this.cpf = this.personalData[0].CPFRESP;

        this.buttons = [
          {
            link: "https://ead.qi.edu.br/login/autologin.php?tokenexterno=" + this.tokenExterno,
            // link: "https://ead.qi.edu.br",
            icon: ["fas", "brain"],
            text: "Moodle",
          },
          {
            link: "https://minhasfaturas.qi.edu.br/faturas/?cpf=" + this.cpf + "&origem=portal-do-aluno",
            icon: ["fas", "shopping-bag"],
            text: "Minhas Faturas",
          },
          {
            link: "https://api-portal.qi.edu.br/auth/pearson/" + this.tokenPearson,
            icon: ["fas", "book"],
            text: "Biblioteca Virtual",
          },
          /*{
            link: "https://vagas.qi.edu.br/",
            icon: ["fas", "briefcase"],
            text: "Central de Vagas",
          },
          ,
          {
            link: "https://qi.edu.br/acontece-aqi/",
            icon: ["fas", "globe"],
            text: "Acontece aQI",
          },
          {
            link: "https://qi.edu.br/blog",
            icon: ["fas", "globe"],
            text: "Blog QI",
          },*/
          {
            link: "https://native01.qi.edu.br/meets/ivsft6fm",
            icon: ["fas", "headset"],
            text: "Secretaria Graduação/Pós",
          },
        ];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      });
  },

  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>
.card-access {
  width: 98.615%;
  padding-top: 8.5px;
  margin: auto;
  background-color: white;
  border: 1.5px solid #b5b5b59a;
  border-radius: 20px;
  box-shadow: 0.5px 5px 5px #b5b5b59a;
}

.spinner-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.spinner {
  border: 2.5px solid rgba(0, 0, 0, 0.1);
  border-left-color: #9b0e1c;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.scroll-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 100%;
}

.scroll-container .button-wrapper {
  display: flex;
  padding-left: 3.5px;
  padding-right: 3.5px;
  padding-bottom: 15px;
}

.scroll-container::-webkit-scrollbar {
  margin-top: 5px !important;
  height: 6.5px;
}

.vs-button-primary.vs-button-filled {
  background: none !important;
}

.button-contrast {
  margin: 0 15px !important;
  padding-top: 3.5px;
  border-radius: 20px;
  color: #9b0e1c;
  border: 1px solid rgba(161, 0, 0, 0.25);
  box-shadow: rgba(161, 0, 0, 0.5) -3.5px 3.5px 1px;
  width: 9.15em;
  height: 7.15em;
  flex: 0 0 auto;
}

.text-button {
  font-size: 0.925em;
  font-weight: bold;
}

.button-contrast:hover {
  font-weight: bold;
  font-size: 0.815em;
  border: 1px solid rgba(70, 0, 0, 0.25);
  box-shadow: rgba(70, 0, 0, 0.75) -3.5px 3.5px 1px,
    rgba(70, 0, 0, 0.15) -2.5px 10px 15px !important;
  color: rgb(70, 0, 0) !important;
}

.icon-contrast {
  margin: 10px auto;
}

.carousel-button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 2em;
  z-index: 2;
  color: #9b0e1c;
}

.carousel-button.prev {
  position: absolute;
  left: 10px;
}

.carousel-button.next {
  position: absolute;
  right: 10px;
}

@media (max-width: 1255px) {
  .card-access {
    width: 98.5%;
  }
}

@media (max-width: 1135px) {
  .card-access {
    width: 98.815%;
  }
}

@media (max-width: 1015px) {
  .card-access {
    width: 98.5%;
  }
}

@media (max-width: 935px) {
  .card-access {
    width: 98.85%;
  }
}

@media (max-width: 845px) {
  .card-access {
    width: 96.85%;
  }
}

@media (max-width: 790px) {
  .card-access {
    width: 97.75%;
  }
}

@media (max-width: 540px) {
  .col-access {
    width: 100% !important;
  }
}

@media (max-width: 500px) {
  .card-access {
    width: 96.75%;
  }
}
</style>



