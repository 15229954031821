<template>
  <div class="carousel-container">
    <div v-if="infoBanner[currentIndex].IMAGE64" class="carousel">
      <a :href="infoBanner[currentIndex].LINK" target="_blank">
        <div class="carousel-image-container">
          <img
            :src="'data:image/jpeg;base64,' + infoBanner[currentIndex].IMAGE64"
            :alt="infoBanner[currentIndex].TITULO"
            class="carousel-image"
            :style="infoBanner[currentIndex].INSTITUCIONAL == 'S' ? 'margin-top: 20px; border-radius: 18.5px' : ''"
          />
          <div v-if="infoBanner[currentIndex].INSTITUCIONAL != 'S'" class="carousel-caption">
            {{ infoBanner[currentIndex].TITULO }}
          </div>
        </div>
      </a>
      <div v-if="this.infoBanner.length > 1">
        <button @click="prev" class="carousel-button prev">
        <font-awesome-icon :icon="['fas', 'chevron-circle-left']" />
      </button>
      <button @click="next" class="carousel-button next">
        <font-awesome-icon :icon="['fas', 'chevron-circle-right']" />
      </button>
      </div>
      
    </div>
    <div v-else class="error-container">
      <div class="error-content">
        <font-awesome-icon
          class="error-icon"
          :icon="['fas', 'calendar']"
        />
        <h1 style="margin-top: 10px">Nenhum Evento Disponível</h1>
        <p style="margin-top: 5px">
          Atualmente, não há eventos em exibição. <br />
          Fique atento às próximas novidades!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    infoBanner: Array,
  },
  data() {
    return {
      currentIndex: 0,
      autoSlideInterval: null,
    };
  },
  mounted() {
    this.startAutoSlide();
  },
  beforeDestroy() {
    this.stopAutoSlide();
  },
  methods: {
    next() {
      this.currentIndex = (this.currentIndex + 1) % this.infoBanner.length;
      this.resetAutoSlide();
    },
    prev() {
      this.currentIndex =
        (this.currentIndex - 1 + this.infoBanner.length) %
        this.infoBanner.length;
      this.resetAutoSlide();
    },
    goToSlide(index) {
      this.currentIndex = index;
      this.resetAutoSlide();
    },
    startAutoSlide() {
      if (this.infoBanner.length > 1) {
        this.autoSlideInterval = setInterval(() => {
          this.next();
        }, 5000);
      }
    },
    stopAutoSlide() {
      if (this.autoSlideInterval) {
        clearInterval(this.autoSlideInterval);
        this.autoSlideInterval = null;
      }
    },
    resetAutoSlide() {
      this.stopAutoSlide();
      this.startAutoSlide();
    },
  },
};
</script>

<style>
.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel {
  position: relative;
  width: 100%;
}

.carousel-image-container {
  position: relative;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 18.5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: block;
}

.carousel-caption {
  bottom: 0;
  margin-top: 0px;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75));
  color: white;
  font-size: 1.075em;
  text-align: center;
  border-bottom-left-radius: 18.5px;
  border-bottom-right-radius: 18.5px;
}

.carousel-button {
  position: absolute;
  top: 50%;
  border-radius: 50px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 7.5px;
  cursor: pointer;
  z-index: 1;
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

.carousel-indicators {
  text-align: center;
  margin-top: 10px;
}

.carousel-indicators span {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.carousel-indicators span.active {
  background-color: #9b0e1c;
}

.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.error-content {
  text-align: center;
  margin-top: 80px;
}

.error-icon {
  font-size: 60px;
}

@media screen and (max-width: 975px) {
  .error-content {
    margin-top: 85px;
  }

  .error-content > h1 {
    font-size: 1.25em;
  }

  .error-content > p {
    font-size: 0.85em;
  }

  .error-icon {
    font-size: 50px;
  }
}

@media screen and (max-width: 900px) {
  .carousel-caption {
    margin-bottom: 20px;
  }

  .carousel-image {
    height: 100%;
  }

  .error-content {
    margin-top: 5px;
  }
}

@media screen and (max-width: 360px) {
  .error-content > h1 {
    font-size: 1.15em;
  }

  .error-content > p {
    font-size: 0.75em;
  }

  .error-icon {
    font-size: 45px;
  }
}

@media screen and (max-width: 320px) {
  .error-content > h1 {
    font-size: 1.115em;
  }

  .error-content > p {
    font-size: 0.65em;
  }

  .error-icon {
    font-size: 40px;
  }
}
</style>
